<template>
  <v-app>
    <v-main>
      <v-app-bar
        image="https://cdn.pixabay.com/photo/2016/06/28/05/10/laptop-1483974_1280.jpg"
      >
        <template v-slot:prepend>
          <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer">
          </v-app-bar-nav-icon>
        </template>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer">
        <v-list>
          <v-list-item
            prepend-icon="mdi-ferry"
            title="Home"
            value="home"
            v-on:click="hideDrawer"
            to="/"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-badge-account-horizontal-outline"
            title="About"
            value="about"
            to="/about"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-domain"
            title="Company"
            value="company"
            to="/company"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-forum"
            title="Contact"
            value="contact"
            to="/contact"
          ></v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-container>
        <v-row>
          <v-parallax
            src="https://cdn.pixabay.com/photo/2017/02/09/09/11/starry-sky-2051448_1280.jpg"
          >
            <div
              class="d-flex flex-column fill-height justify-center align-center text-white"
            >
              <h2 class="subheading">ITで"真の価値"を提供</h2>
              <h4 class="subheading">合同会社エクスシップス</h4>
              <h5 class="subheading">exships LLC.</h5>
            </div>
          </v-parallax>
        </v-row>
        <v-row>
          <v-parallax
            src="https://cdn.pixabay.com/photo/2016/12/18/14/31/lake-1915846_1280.jpg"
          >
            <div
              class="d-flex flex-column fill-height justify-center align-center text-black"
            >
              <h2 class="subheading">アプリケーション開発のその先へ</h2>
              <h4 class="subheading">iOS/Android</h4>
              <h5 class="subheading">smartphone</h5>
            </div>
          </v-parallax>
        </v-row>
        <v-row>
          <v-parallax
            src="https://cdn.pixabay.com/photo/2020/06/25/11/06/galaxy-5339322_1280.jpg"
          >
            <div
              class="d-flex flex-column fill-height justify-center align-center text-white"
            >
              <h2 class="subheading">チーム開発の価値を高める</h2>
              <h4 class="subheading">Agile/Scrum</h4>
              <h5 class="subheading">Team Building</h5>
            </div>
          </v-parallax>
        </v-row>
        <v-row>
          <v-parallax
            src="https://cdn.pixabay.com/photo/2022/12/26/13/43/ship-7679100_1280.jpg"
          >
            <div
              class="d-flex flex-column fill-height justify-center align-center text-black"
            >
              <h2 class="subheading">技術への挑戦を継続する</h2>
              <h4 class="subheading">Failure teaches success.</h4>
              <h5 class="subheading">Programming Languages</h5>
            </div>
          </v-parallax>
        </v-row>
      </v-container>
      <v-footer color="primary" app> © 2020 - 2024 exships LLC. </v-footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
  }),
  methods: {
    hideDrawer: function () {
      this.drawer = false;
    },
  },
};
</script>
